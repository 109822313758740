import httpClient from '../baseurl'
import Cookies from 'js-cookie'
export async function loginservice(data) {
    return httpClient.post(
        'auth.php', data, {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    )
}

export async function changePasswordservice(data) {
    return httpClient.post(
        'update_password.php', JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}
