import Cookies from 'js-cookie'
import httpClient from '../baseurl'
export async function getDashboardDetails(user_id) {
    return httpClient.post(
        `all_count.php`, JSON.stringify({ userid: user_id }), {
        headers: {
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}


export async function updateBasicsetting(data) {

    return httpClient.post(
        `update_basic_settings.php`, JSON.stringify(data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )

}

export async function getsettingDetails(user_id) {

    return httpClient.post(
        `get_basic_settings.php`, JSON.stringify({ userid: user_id }), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )

}

