import { Link } from "react-router-dom"
import './changepassword.css'
import { useFormik } from "formik"
import { changePasswordschema } from "../../lib/schema"
import { toast, ToastContainer } from "react-toastify"
import { changePasswordservice } from "../../services/Auth/login"
function ChangePassword() {
    let userData = JSON.parse(localStorage.getItem('user'));
    const { values, errors, touched, handleBlur, handleChange, handleSubmit
    } = useFormik({
        initialValues: {
            old_pass: '',
            new_pass: '',
            retype_pass: ''
        },
        validationSchema: changePasswordschema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async () => {
            let payload_data = {
                userid: userData.userid,
                oldpassword: values.old_pass,
                newpassword: values.new_pass
            }
            changePasswordservice(payload_data).then((res) => {
                if (res.error == false) {
                    toast.error(res.data.message)
                } else {
                    toast.success(res.data.message)
                }
            })

        }

    })
    return (
        <>
            <ToastContainer />
            <div className='pageHdn'>
                <h1>Change Password</h1>
                <span><Link to='/dashboard'>Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i><Link>Change Password</Link></span>
            </div>
            <form className='settingForm' onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='fdormGroup'>
                            <label>Old Password</label>
                            <input
                                name='old_pass'
                                id='old_pass'
                                autoCapitalize='none'
                                autoCorrect='off'
                                type='password'
                                placeholder='Old Password'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.old_pass}

                            />
                            <div className='error_div'>
                                {
                                    touched.old_pass ? (
                                        <small className='errorText'>{errors.old_pass}</small>
                                    )
                                        : null
                                }
                            </div>

                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='fdormGroup'>
                            <label>New Password</label>
                            <input
                                name='new_pass'
                                id='new_pass'
                                autoCapitalize='none'
                                autoCorrect='off'
                                type='password'
                                placeholder='New Password'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.new_pass}
                            />
                            <div className='error_div'>
                                {
                                    touched.new_pass ? (
                                        <small className='errorText'>{errors.new_pass}</small>
                                    )
                                        : null
                                }
                            </div>

                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='fdormGroup'>
                            <label>Re-Type Password</label>
                            <input
                                name='retype_pass'
                                id='retype_pass'
                                autoCapitalize='none'
                                autoCorrect='off'
                                type='password'
                                placeholder='Re-Type Password'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.retype_pass}
                            />
                            <div className='error_div'>
                                {
                                    touched.retype_pass ? (
                                        <small className='errorText'>{errors.retype_pass}</small>
                                    )
                                        : null
                                }
                            </div>

                        </div>
                    </div>

                </div>
                <button type='submit' className='themBtns'>Submit</button>

            </form>
        </>
    )
}

export default ChangePassword