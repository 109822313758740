import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  HashRouter,
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements
} from "react-router-dom";
import Login from './Pages/Login/Login';
import Layout from './Pages/Layout/Layout';
import Dashboard from './Pages/Dashboard/Dashboard';
import BasicSetting from './Pages/BasicSetting/BasicSetting';
import ManageUser from './Pages/ManageUser/ManageUser';
import ManageCustomer from './Pages/ManageCustomer/ManageCustomer';
import ManageBusinessType from './Pages/ManageBusinessType/ManageBusinessType';
import AddBusinessType from './Pages/ManageBusinessType/AddBusinessType';
import HsnCode from './Pages/HsnCode/HsnCode';
import HsnCodeAdd from './Pages/HsnCode/HsnCodeAdd';
import Invoice from './Pages/Invoice/Invoice';
import App from './App'
import Banner from './Pages/Banner';
import AddeditBanner from './Pages/Banner/addEditBanner';
import Updateuser from './Pages/ManageUser/editUser';
import ChangePassword from './Pages/changePassword/changepassword';
const root = ReactDOM.createRoot(document.getElementById('root'));


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<Login />} />

      <Route path="/" element={<Layout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="settings" element={<BasicSetting />} />
        <Route path="banner" element={<Banner />} />
        <Route path="addbanner" element={<AddeditBanner />} />
        <Route path="manageuser" element={<ManageUser />} />
        <Route path="managecustomer" element={<ManageCustomer />} />
        <Route path="managebusiness" element={<ManageBusinessType />} />
        <Route path="addbusiness" element={<AddBusinessType />} />
        <Route path="hsncode" element={<HsnCode />} />
        <Route path="hsncodeadd" element={<HsnCodeAdd />} />
        <Route path="invoice" element={<Invoice />} />
        <Route path="changepassword" element={<ChangePassword />} />

        {/* ... etc. */}
      </Route>
    </>

  )
);

root.render(
  <RouterProvider

    router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
