// import httpClient from '../baseurl'
import Cookies from 'js-cookie'
import httpClient from '../baseurl'
export async function getinvoiceList(user_id, offset, limit) {
    return httpClient.post(
        `/invoice/all_invoice.php`, JSON.stringify({ userid: user_id, offset: offset, limit: limit }), {
        headers: {
            // "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}

export async function exportSalesxlx(_data) {
    return httpClient.post(
        `invoice/dataxls/datacollect.php`, JSON.stringify(_data), {
        headers: {
            // "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}

export async function exportHsnxlx(_data) {
    return httpClient.post(
        `invoice/dataxls/datacollect_hsn.php`, JSON.stringify(_data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}