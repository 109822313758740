import './Dashboard.css';
import customer from "../../assets/customer.png"
import { useEffect, useState } from 'react'
import { getDashboardDetails } from '../../services/dashboard/dashboard'
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
   const navigate = useNavigate()
   let userData = JSON.parse(localStorage.getItem('user'));
   const [userDetails, setUserDetails] = useState({})
   useEffect(() => {
      getDashboardDetails(userData.userid).then((res) => {
         if (res.status == 200) {
            setUserDetails(res.data.data)
            // toast.success(res.data.message)
         }
      })
   }, [])
   return (
      <div className="dasboard">
         <ToastContainer/>
         <div className='pageHdn'>
            <h1>Create Invoice</h1>
         </div>
         <div className='row'>
            <div className='col-md-3'>
               <div className='cardItem' 
               onClick={() => navigate('/invoice')}
               >
                  
                  <div className='cardTop'>
                     <span>
                        <img src={customer} />
                     </span>
                     <b>{userDetails?.total_invoice}</b>
                  </div>
                  <div className='cardBtm'>
                     <h2>Total Invoice</h2>
                  </div>
               </div>
            </div>
            <div className='col-md-3'>
               <div className='cardItem' 
               onClick={() => navigate('/managecustomer')}
               >
                  <div className='cardTop'>
                     <span>
                        <img src={customer} />
                     </span>
                     <b>{userDetails?.total_customer}</b>
                  </div>
                  <div className='cardBtm'>
                     <h2>Total Customer</h2>
                  </div>
               </div>
            </div>
            <div className='col-md-3'>
               <div className='cardItem'>
                  <div className='cardTop'>
                     <span>
                        <img src={customer} />
                     </span>
                     <b>₹ {userDetails?.total_sale}</b>
                  </div>
                  <div className='cardBtm'>
                     <h2>Total Sale</h2>
                  </div>
               </div>
            </div>
            <div className='col-md-3'>
               <div className='cardItem'>
                  <div className='cardTop'>
                     <span>
                        <img src={customer} />
                     </span>
                     <b>{userDetails?.total_product}</b>
                  </div>
                  <div className='cardBtm'>
                     <h2>Total Product</h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Dashboard;