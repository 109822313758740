import Cookies from 'js-cookie'
import httpClient from '../baseurl'

export async function getBusinessTypelist(user_id, offset, limit) {

    return httpClient.post(
        'all_business_type.php', JSON.stringify({ userid: user_id, offset: offset, limit: limit }), {
        headers: {
            // "Content-type": "application/json",
            // 'Accept':'application/json',
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}

export async function addbusinessType(_data) {
    return httpClient.post(
        'business_type_add.php', JSON.stringify(_data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,

        }
    }
    )
}

export async function updateBusinessType(_data) {
    return httpClient.post(
        'update_business_type.php', JSON.stringify(_data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )

}

export async function deleteBusinesstype(data) {
    return httpClient.post(
        'delete_business_type.php', JSON.stringify(data), {
        headers: {
            'Authorization': `${Cookies.get('token')}`,

        }
    }
    )
}

export async function getdetailsByid(data) {
    return httpClient.post(
        'fetch_business_type.php', JSON.stringify(data), {
        headers: {
            'Authorization': `${Cookies.get('token')}`,

        }
    }
    )
}