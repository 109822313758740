import './ManageBusinessType.css';
import { Link } from 'react-router-dom';
import edit from "../../assets/edit.png";
import trst from "../../assets/delete.png";
import { useNavigate } from 'react-router-dom';
import TableDataPagination from '../../Component/tableComponent/tablePagination';
import { useEffect, useState } from 'react';
import { deleteBusinesstype, getBusinessTypelist } from '../../services/businessType';
import { toast, ToastContainer } from 'react-toastify';

const ManageBusinessType = () => {
    const [toatlCount, setTotalCount] = useState(0)
    const items = Array.from({ length: toatlCount }, (_, index) => `Item ${index + 1}`);
    const [limit, setLimit] = useState(10)
    let userData = JSON.parse(localStorage.getItem('user'));
    const [selectFilter, setselectFilter] = useState(3)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(items.length / limit);
    const [originalData, setoriginalData] = useState([])
    const navigate = useNavigate()
    const [businessTypelist, setBusinessTypeList] = useState([])
    const [searchinput, setSearhInput] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    // get businesstype list
    function getBusinessTypelistData() {
        getBusinessTypelist(userData.userid, currentPage, limit).then((res) => {

            if (res.status == 200) {
                setoriginalData(res.data.data)
                setTotalCount(res.data.total_count)
            }

        })
    }
    useEffect(() => {
        getBusinessTypelistData()
    }, [currentPage, limit])

    // delete business type
    function deleteitem(id) {

        deleteBusinesstype({
            userid: userData.userid,
            bsuiness_type_id: id
        }).then((res) => {
            if (res.status == 200) {
                getBusinessTypelistData()
                toast.success(res.data.message)

            }
        })
    }

    // filtred data
    useEffect(() => {
        if (originalData != null) {
            if (selectFilter == 0) {
                let _filterArray = [...originalData].filter(i => i.status == 'false')
                setBusinessTypeList(_filterArray)
            } else if (selectFilter == 1) {
                let _filterArray = [...originalData].filter(i => i.status == 'true')
                setBusinessTypeList(_filterArray)
            } else {
                setBusinessTypeList([...originalData])
            }
        } else {
            return
        }

    }, [selectFilter, originalData])

    function onEdit(item) {
        navigate(`/addbusiness?id=${item.userid}`)
    }
  // Function to filter contacts and return the count of matching items
  function searchBusinesstypeDetails() {
    const lowerCaseSearchValue = searchinput.toLowerCase();

    const filteredContacts = businessTypelist.filter(contact =>
        contact.business_type.toLowerCase().includes(lowerCaseSearchValue)
    );

    setBusinessTypeList(filteredContacts)
    setTotalCount(filteredContacts.length)
}
    return (
        <div className="dasboard ManageBusinessType">
            <ToastContainer />
            <div className='pageHdn'>
                <h1>Manage business type</h1>
                <span><Link to="/dashboard">Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i>

                    <Link>Manage business type</Link></span>
            </div>
            <div className='comCard'>
                <div className='comnFltr'>
                    <div className='fltrfrm'>
                        <div className='frmGrp flxFrm'>
                            <input 
                            type='text' 
                            placeholder='Search ...'
                                value={searchinput}
                                onChange={(val) => {
                                    if (!val.target.value) {
                                        setBusinessTypeList(originalData)
                                    }
                                    setSearhInput(val.target.value)}}
                                onKeyDown={() => {
                                    if (searchinput === '') {
                                        setBusinessTypeList(originalData)
                                    }
                                }}

                            
                            />
                            <button 
                            type='button' 
                            className='goBtn'
                            onClick={() => searchBusinesstypeDetails()}
                            >Go</button>
                        </div>
                        <div className='frmGrp'>
                            <select
                                value={selectFilter}
                                onChange={(val) => setselectFilter(val.target.value)}
                            >
                                <option>Select status</option>
                                <option value={3} >All</option>
                                <option value={1}>Active</option>
                                <option value={0}>Inactive</option>
                            </select>

                        </div>
                    </div>

                    <div className='fltrfrmbtn'>
                        <button type='button' className="exportBtn" onClick={() => { navigate("/addbusiness") }}>Add</button>
                    </div>
                </div>
                <div className='tableresponsive'>
                    <table>
                        <thead>
                            <tr>
                                {/* <th><div className='checkbox'><input type="checkbox" id="html" /><label for="html"></label></div></th> */}
                                <th>SI</th>
                                <th>Business type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                businessTypelist?.map((item, index) => {

                                    return (
                                        <tr>
                                            {/* <td><div className='checkbox'><input type="checkbox" id={item.id} /><label for={item.id}></label></div></td> */}
                                            <td>
                                                #{
                                                    index + 1
                                                }
                                            </td>
                                            <td>{item.business_type}</td>
                                            <td>{item.status == 'true' ? <span className='active'>Active</span> : <span>Inactive</span>}</td>
                                            <td>
                                                <div className='action'>
                                                    <Link

                                                        onClick={() => { onEdit(item) }}
                                                    > <img src={edit} /></Link>
                                                    <Link onClick={() => deleteitem(item.userid)} ><img src={trst} /></Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>

                    </table>
                    <div className='nodata-found-div'>
                        {
                            businessTypelist?.length == 0 ?
                                <p>No Data Found</p>
                                //  <img 
                                //  className='table-image'
                                //  src={require('../../assets/OIP.png')}/>
                                : null
                        }
                    </div>
                </div>
                <div className='tableFtr'>
                    <div className='tblFtrLft'>
                        <label>Show</label>
                        <select
                            value={limit} onChange={(val) => {
                                setLimit(val.target.value);
                                setCurrentPage(1)
                            }}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>

                        </select>
                    </div>

                    <TableDataPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />

                </div>
            </div>

        </div>
    )
}
export default ManageBusinessType;