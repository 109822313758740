import { Link, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import edit from "../../assets/edit.png";
import trst from "../../assets/delete.png";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { deleteBanner, getBannerList } from '../../services/banner';
import TableDataPagination from '../../Component/tableComponent/tablePagination';
import { gethsndetailsByid } from '../../services/Hsn';
import { toast, ToastContainer } from 'react-toastify';

const ManageBusinessType = () => {
    const [toatlCount, setTotalCount] = useState(0)
    const items = Array.from({ length: toatlCount }, (_, index) => `Item ${index + 1}`);
    const [limit, setLimit] = useState(10)
    let userData = JSON.parse(localStorage.getItem('user'));
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(items.length / limit);
    const [bannerlist, setBannerData] = useState([])
    const navigate = useNavigate()
    const [searchinput, setSearhInput] = useState('')
    const[originalData , setOriginalData] = useState([])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    // get businesstype list
    function getbannerlist() {
        getBannerList(userData.userid, currentPage, limit).then((res) => {

            if (res.status == 200) {
                setBannerData(res.data.data)
                setOriginalData(res.data.data)
                setTotalCount(res.data.total_count)
            }

        })
    }
    useEffect(() => {
        getbannerlist()
    }, [currentPage, limit])

    // delete business type
    function deleteitem(id) {

        deleteBanner({
            userid: userData.userid,
            bannerid: id
        }).then((res) => {
            if (res.status == 200) {
                getbannerlist()
                toast.success(res.data.message)

            }
        })
    }

    function onEdit(item) {
        navigate(`/addbanner?id=${item.id}`)
    }


     // Function to filter contacts and return the count of matching items
     function searchbyBannerDetails() {
        const lowerCaseSearchValue = searchinput.toLowerCase();
        //  console.log(searchinput,'vlue...s')
        const filteredContacts = bannerlist.filter(contact =>
            contact.name.toLowerCase().includes(lowerCaseSearchValue) 
        );

        setBannerData(filteredContacts)
        setTotalCount(filteredContacts.length)
    }
    return (
        <div className="dasboard ManageBusinessType">
            <div>
                <ToastContainer/>
            </div>
            <div className='pageHdn'>
                <h1>Manage Banner</h1>
                <span><Link to="/layout">Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i><Link>Banner</Link></span>
            </div>
            <div className='comCard'>
                <div className='comnFltr'>
                    <div className='fltrfrm'>
                        <div className='frmGrp flxFrm'>
                            <input type='text' 
                            placeholder='Search ...' 
                            value={searchinput}
                            onChange={(val) => {
                                if (!val.target.value) {
                                    setBannerData(originalData)
                                }
                                setSearhInput(val.target.value)}}
                            onKeyDown={() => {
                                if (searchinput === '') {
                                setBannerData(originalData)
                                }
                              }}
                            />
                            <button
                             type='button' 
                             className='goBtn'
                             onClick={() => searchbyBannerDetails()}
                             >Go</button>
                        </div>

                    </div>

                    <div className='fltrfrmbtn'>
                        <button type='button' className="exportBtn" onClick={() => { navigate("/addbanner") }}>Add</button>
                    </div>
                </div>
                <div className='tableresponsive'>
                    <table>
                        <thead>
                            <tr>
                                {/* <th><div className='checkbox'><input type="checkbox" id="html" /><label for="html"></label></div></th> */}
                                <th>SI</th>
                                <th>Banner Name</th>
                                <th>image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bannerlist?.map((item, index) => {

                                    return (
                                        <>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <img
                                                        className='notification_image'
                                                        src={item.banner_image}
                                                    />
                                                </td>
                                                <td>
                                                    <div className='action'>
                                                        <Link
                                                            onClick={() => onEdit(item)}
                                                        > <img src={edit} /></Link>
                                                        <Link onClick={() => deleteitem(item.id)} ><img src={trst} /></Link>

                                                    </div>
                                                </td>
                                            </tr>
                                        </>

                                    )
                                })
                            }

                        </tbody>
                    </table>
                    <div className='nodata-found-div'>
                            {
                                bannerlist?.length == 0 ?
                                    <p>No Data Found</p>
                                    //  <img 
                                    //  className='table-image'
                                    //  src={require('../../assets/OIP.png')}/>
                                    : null
                            }
                        </div>
                </div>
                <div className='tableFtr'>
                    <div className='tblFtrLft'>
                        <label>Show</label>
                        <select
                            value={limit} onChange={(val) => {
                                setLimit(val.target.value);
                                setCurrentPage(1)
                            }}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>

                        </select>
                    </div>

                    <TableDataPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />

                </div>
            </div>

        </div>
    )
}
export default ManageBusinessType;