import './Login.css';
import logo from "../../assets/logo.png";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { loginschema } from '../../lib/schema';
import { loginservice } from '../../services/Auth/login';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import {
    ToastContainer, toast
} from 'react-toastify';
const Login = () => {
    let initial_value = {
        email: '',
        password: ''
    }
    const navigate = useNavigate();
    const [loginFaild, setloginFaild] = useState(false);
    const [invalidmessage, setInvalidMessage] = useState('')
    const { values, errors, touched, handleBlur, handleChange, handleSubmit
    } = useFormik({
        initialValues: initial_value,
        validationSchema: loginschema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async () => {
            // const formData = new FormData();
            // formData.append('email', values.email)
            // formData.append('password', values.password)
            let _data = {
                email: values.email,
                password: values.password
            }
            loginservice(_data).then((res) => {
                if (res.data.error == true) {
                    setloginFaild(true)
                    setInvalidMessage(res.data.message)
                    // toast.error(res.data.message)

                    return
                }

                if (res.data.error == false) {
                    Cookies.set('token', res.data.data.token, { expires: 1 })
                    localStorage.setItem('user', JSON.stringify(res.data.data))
                    navigate('/dashboard')
                    setTimeout(() => {
                        toast.success(res.data.message)

                    }, 1000);
                }
                // console.log(_data, 'fajsfas')
                // console.log(res.data.data,
                //     'toenfdsfsdfd'
                // )
            }).catch((err) => {
            })
        }

    })

    useEffect(() => {
        console.log(errors, 'errrr')
    }, [])
    return (
        <div className="login">
            <ToastContainer  
            
            autoClose={1000}
            />
            <div className="loginfrm">
                <img src={logo} />
                <form onSubmit={handleSubmit}>
                    {
                        loginFaild ? (
                            <small className='errorText'>{invalidmessage}</small>

                        ) : null
                    }
                    <div className='formGroup'>
                        <label>Login</label>
                        <input
                            name='email'
                            id='email'
                            autoCapitalize='none'
                            autoCorrect='off'
                            type='email'
                            placeholder='Email'
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <div className='error_div'>
                            {
                                touched.email ? (
                                    <small className='errorText'>{errors.email}</small>
                                )
                                    : null
                            }
                        </div>

                    </div>
                    <div className='formGroup'>
                        <label>Password</label>
                        <input
                            name='password'
                            id='password'
                            autoCapitalize='none'
                            autoCorrect='off'
                            type='password'
                            placeholder='password'
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        <div className='error_div'>
                            {
                                touched.password ? (
                                    <small className='errorText'>{errors.password}</small>
                                )
                                    : null
                            }
                        </div>
                    </div>
                    <div className='frmBtm'>
                        {/* <Link to="">Forgot your password?</Link> */}
                        <button type='submit' className='thnBtn'
                        >Login</button>
                    </div>
                </form>
            </div>
            {/* <ToastContainer 
            // autoClose={1000}
            /> */}
        </div>
    )
}
export default Login;