import { Link, useLocation, useNavigate } from 'react-router-dom';
import './HsnCode.css';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { addHsnNewSchema } from '../../lib/schema';
import { getBusinessTypelist } from '../../services/businessType';
import { addhsnnNew, gethsndetailsByid, UpdateHsnCode } from '../../services/Hsn';
import { toast, ToastContainer } from 'react-toastify';
const HsnCodeAdd = () => {
  let userData = JSON.parse(localStorage.getItem('user'));
  const [businessTypeList, setBusinessTypeList] = useState([])
  const [selectBusinessType, setSelectBusinessType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search); // Parse query string
  const id = queryParams.get('id');
  const [initial_value, setInitialValue] = useState({
    userid: userData.userid,
    code: '',
    gst_rate: '',
    cess: '',
    bsuiness_type: '',
    status: 'true'

  })


  useEffect(() => {
    getBusinessTypelist(userData.userid, 1, 100).then((res) => {
      if (res.status == 200) {
        setBusinessTypeList(res.data.data)
      }

    })
  }, [])

  useEffect(() => {
    if (id != undefined) {
      gethsndetailsByid({ userid: userData.userid, hsnid: id }).then((res) => {
        setInitialValue({
          ...initial_value, code: res.data.data.code,
          gst_rate: res.data.data.gst_rate,
          cess: res.data.data.cess,
          bsuiness_type: res.data.data.business_type_name
        })
      })
    }

  }, [id])

  // get busnesstypeName

  function getBusinessTypeName(bsuiness_type_Name) {

    console.log(bsuiness_type_Name);
    [...businessTypeList].filter(i => {

      if (i.business_type === bsuiness_type_Name) {
        values.bsuiness_type = bsuiness_type_Name

        let _data = { id: i.userid, name: i.business_type }

        console.log(_data)
        setSelectBusinessType(_data)

      }
    })


  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit
  } = useFormik({
    initialValues: initial_value,
    validationSchema: addHsnNewSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async () => {

      if (id != undefined) {
        let _uploadData = { ...values, hsn_id: id, bsuiness_type: selectBusinessType, }
        console.log(_uploadData)
        UpdateHsnCode(_uploadData).then((res) => {
          if (res.status == 200) {
            toast.success(res.data.message)
            setTimeout(() => {
              navigate('/hsncode')

            }, 1000);
          }
        })
      } else {
        const modifiedData = {
          ...values, // Spread existing properties
          bsuiness_type: selectBusinessType, // Update bsuiness_type
        };
        console.log(modifiedData, 'modify data.')
        addhsnnNew(modifiedData).then((res) => {
          if (res.data.error == false) {
            setTimeout(() => {
              toast.success(res.data.message)

            }, 1000);
            navigate('/hsncode')
          } else if (res.data.error == true) {
            toast.error(res.data.message)
          }
        })
      }
    }
  })
  useEffect(() => {
    getBusinessTypeName(values.bsuiness_type)

  }, [values.bsuiness_type])

  // get businessType

  function onChangeBusinesstype(name) {
    [...businessTypeList].filter(i => {

      if (i.business_type === name) {
        values.bsuiness_type = i.business_type;

        let _data = { id: i.userid, name: i.business_type }
        console.log(_data)
        setSelectBusinessType(_data)

      }
    })

  }
  return (
    <div className="BasicSetting ">
      <ToastContainer />
      <div className='pageHdn'>
        <h1>Add HSN Code Master</h1>
        <span><Link to="/dashboard">Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i>
          <Link to="/hsncode">HSN Master</Link><i class="fa fa-angle-right" aria-hidden="true"></i>
          <Link>Add HSN Code Master</Link></span>
      </div>
      <form className='settingForm' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>Business Type</label>
              <select
                id='bsuiness_type'
                name='bsuiness_type'
                value={values.bsuiness_type}
                onChange={(val) => onChangeBusinesstype(val.target.value)}


                onBlur={
                  handleBlur

                }
              >
                <option selected>
                  Select Business Type
                </option>
                {
                  businessTypeList.map((item) => {
                    return (
                      <option value={item.business_type}>
                        {item.business_type}
                      </option>

                    )
                  })
                }

              </select>

              <div className='error_div'>
                {
                  touched.bsuiness_type ? (
                    <small className='errorText'>{errors.bsuiness_type
                    }</small>
                  )
                    : null
                }
              </div>

            </div>
          </div>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>HSN code</label>
              <input
                id='code'
                name='code'
                value={values.code}

                onChange={handleChange}

                onBlur={
                  handleBlur

                }
                type="text"


              />

              <div className='error_div'>
                {
                  touched.code && errors ? (
                    <small className='errorText'>{errors.code}</small>
                  )
                    : null
                }
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>gst rate</label>
              <input
                id='gst_rate'
                name='gst_rate'
                value={values.gst_rate}
                onChange={handleChange}
                type="number"
                onBlur={
                  handleBlur

                }

              />

              <div className='error_div'>
                {
                  touched.gst_rate ? (
                    <small className='errorText'>{errors.gst_rate}</small>
                  )
                    : null
                }
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>Cess</label>
              <input
                id='cess'
                name='cess'
                value={values.cess}
                onChange={handleChange}
                type="number"

                onBlur={
                  handleBlur

                }
              />
              {/* <div className='error_div'>
                {
                  touched.cess ? (
                    <small className='errorText'>{errors.cess}</small>
                  )
                    : null
                }
              </div> */}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>status</label>
              <select
                id='status'
                name='status'
                value={values.status}
                onChange={handleChange}

                onBlur={
                  handleBlur

                }
              >

                <option

                  selected
                  value='true'
                >
                  Active
                </option>
                <option
                  value='false'
                >
                  Inactive
                </option>
              </select>

            </div>
          </div>
          <div className='col-md-12'>

            <button type='submit' className='themBtns'>Submit</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default HsnCodeAdd