import { Link } from 'react-router-dom';
import './BasicSetting.css';
import upload from "../../assets/upload.png"
import cross from '../../assets/Frame.png'
import { useFormik } from 'formik';
import { basicsettingSchema } from '../../lib/schema';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import httpClient from '../../services/baseurl';
import { getsettingDetails, updateBasicsetting } from '../../services/dashboard/dashboard';
import { toast, ToastContainer } from 'react-toastify';
const BasicSetting = () => {
  let userData = JSON.parse(localStorage.getItem('user'));
  const [uploadLogo, setUoloadlogo] = useState('')
  const [imagePath, setImagePath] = useState('')
  const [initial_value, setinittalValue] = useState({
    email_id: '',
    phone_number: '',
    address: '',
    about: '',

  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit
  } = useFormik({
    initialValues: initial_value,
    validationSchema: basicsettingSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async () => {
      let updateData = { ...values, url: uploadLogo, userid: userData.userid }
      updateBasicsetting(updateData).then((res) => {
        if (res.status == 200) {
          toast.success(res.data.message)
        } else {
          toast.error('Something Wrong')
        }
      })

    }

  })

  useEffect(() => {
    getsettingDetails(userData.userid).then((res) => {
      setinittalValue(res.data.data)
      setUoloadlogo(res.data.data.url)
    })
  }, [])

  // logoupload 
  function Logoupload(e) {
    let formData = new FormData()
    formData.append('file', e.target.files[0])
    httpClient.post(
      `upload_logo.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${Cookies.get('token')}`,
      }
    }
    ).then((res) => {
      setUoloadlogo(res.data.url)
      console.log(res.data, 'response data...')
    })
  }

  return (
    <div className="BasicSetting">
      <div>
        <ToastContainer />
      </div>

      <div className='pageHdn'>
        <h1>Basic Settings</h1>
        <span><Link to='/dashboard'>Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i><Link>Basic Settings</Link></span>
      </div>
      <form className='settingForm' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>Email ID</label>
              <input
                name='email_id'
                id='email_id'
                autoCapitalize='none'
                autoCorrect='off'
                type='email'
                placeholder='Email'
                value={values.email_id}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <div className='error_div'>
                {
                  touched.email_id && errors.email_id ? (
                    <small className='errorText'>{errors.email_id}</small>
                  )
                    : null
                }
              </div>

            </div>
          </div>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>Phone No</label>
              <input
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}

                name='phone_number'
                id='phone_number'
                autoCapitalize='none'
                autoCorrect='off'
                type='number'
                placeholder='Phone'
                value={values.phone_number}
                onBlur={handleBlur}
                onChange={handleChange}
                maxLength={10}
              />
              <div className='error_div'>
                {
                  touched.phone_number && errors.phone_number ? (
                    <small className='errorText'>{errors.phone_number}</small>
                  )
                    : null
                }
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='fdormGroup'>
              <label>Address</label>
              <input
                name='address'
                id='address'
                autoCapitalize='none'
                autoCorrect='off'
                type='text'
                placeholder='Address'
                value={values.address}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <div className='error_div'>
                {
                  touched.address && errors.address ? (
                    <small className='errorText'>{errors.address}</small>
                  )
                    : null
                }
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='fdormGroup'>
              <label>About</label>
              <textarea
                name='about'
                id='about'
                autoCapitalize='none'
                autoCorrect='off'
                type='text'
                placeholder='About'
                value={values.about}
                onBlur={handleBlur}
                onChange={handleChange}

              ></textarea>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='fdormGroup'>
              <label>Upload Logo</label>
              <div className='upload'>
                <span onChange={(e) => Logoupload(e)}  >
                  <img src={upload} /> Drop files here or click to upload.
                  <input type='file' />
                </span>
              </div>
            </div>
            {
              uploadLogo != '' ?
                <div className='image_container'>
                  <div className='upload_image_view'>
                    <div className='upload_image'>
                      <img
                        className='image_logo'
                        src={uploadLogo}
                      />


                    </div>
                    <img
                      onClick={() => setUoloadlogo('')}
                      className='image'
                      src={cross}
                    />

                  </div>
                </div>
                : null
            }

            <button type='submit' className='themBtns'>Submit</button>
          </div>
        </div>
      </form>

    </div>
  )
}

export default BasicSetting