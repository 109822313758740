import * as Yup from 'yup'

export const loginschema = Yup.object(
    {
        email: Yup.string().email().required('Please Enter  Email'),
        password: Yup.string().required('Please Enter Password')
    }
)

export const basicsettingSchema = Yup.object({
    email_id: Yup.string().email().required('Please Enter Email'),
    phone_number: Yup.number().required('Please Enter Phone Number'),
    address: Yup.string().required('Please Enter Address'),
    about: Yup.string(),
    url: Yup.string()
})
export const businesstypeAdd = Yup.object({
    business_type: Yup.string().required('Please Enter BusinesType Name'),
    userid: Yup.string(),

})
// userid,code,gst_rate,cess,bsuiness_type
export const addHsnNewSchema = Yup.object({
    userid: Yup.string(),
    status: Yup.string(),
    code: Yup.string().required('Please Enter HSN Code'),
    gst_rate: Yup.number().required('Please Enter GST rate'),
    cess: Yup.number(),
    bsuiness_type: Yup.string().required('Please Select BusiessType')

})
export const addbannerschema = Yup.object({
    userid: Yup.string(),
    name: Yup.string().required('Please Enter Banner Name'),
    banner_image: Yup.string().required('Please Upload Banner')
})

export const changePasswordschema = Yup.object({
    old_pass: Yup.string().required('Enter Old Password'),
    new_pass: Yup.string().required('Enter New Password'),
    retype_pass: Yup.string()
        .required('Enter Re-Type Password')
        .oneOf([Yup.ref('new_pass')], 'Passwords must match')

})