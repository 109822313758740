// import httpClient from '../baseurl'
import Cookies from 'js-cookie'
import httpClient from '../baseurl'
export async function getCustommerList(user_id, offset, limit) {
    return httpClient.post(
        `all_customer.php`, JSON.stringify({ userid: user_id, offset: offset, limit: limit }), {
        headers: {
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}