import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import TableDataPagination from '../../Component/tableComponent/tablePagination';
import './Invoice.css';
import { Link } from 'react-router-dom';
import { exportHsnxlx, exportSalesxlx, getinvoiceList } from '../../services/Invoice';
import moment from 'moment';

const Invoice = () => {
    const [toatlCount, setTotalCount] = useState(0)
    const items = Array.from({ length: toatlCount }, (_, index) => `Item ${index + 1}`);
    const [limit, setLimit] = useState(10)
    let userData = JSON.parse(localStorage.getItem('user'));
    const [selectFilter, setselectFilter] = useState(3)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(items.length / limit);
    const [originalData, setoriginalData] = useState([])
    const [invoicelist, setInvoiceList] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [searchinput, setSearhInput] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    // get businesstype list
    function getinvoicelistData() {
        getinvoiceList(userData.userid, currentPage, limit).then((res) => {

            if (res.status == 200) {
                setInvoiceList(res.data.data)
                setoriginalData(res.data.data)
                setTotalCount(res.data.total_count)
            }

        })
    }
    useEffect(() => {
        getinvoicelistData()
    }, [currentPage, limit])

    function exporsaleData() {
        if (startDate == '') {
            toast.error('Please Select Start Date')
            return
        } else if (endDate == '') {
            toast.error('Please Select End Date')
            return
        }
        exportSalesxlx({ userid: userData.userid, start_date: startDate, end_date: endDate }).then((res) => {

            if (res.data.error == true) {

                toast.error(res.data.message)

                return
            }
            if (res.data && res.data.xzlsurl) {
                const handleDownload = () => {
                    const link = document.createElement('a');
                    link.href = res.data.xzlsurl; // Replace with your file path
                    link.download = 'Invoice_total_excel_sheet.xlsx'; // The name the file will be downloaded as
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };
                handleDownload()
            }

        })
    }

    function exporhsnData() {
       
            if (startDate == '') {
                toast.error('Please Select Start Date')
                return
            } else if (endDate == '') {
                toast.error('Please Select End Date')
                return
            }

            exportHsnxlx({ userid: userData.userid, start_date: startDate, end_date: endDate }).then((res) => {
                if (res.data.error == true) {

                    toast.error(res.data.message)

                    return
                }
                if (res.data && res.data.xzlsurl) {
                    const handleDownload = () => {
                        const link = document.createElement('a');
                        link.href = res.data.xzlsurl; // Replace with your file path
                        link.download = 'Invoice_hsn_excel_sheet.xlsx'; // The name the file will be downloaded as
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    };
                    handleDownload()
                }

            })
        }
        // Function to filter contacts and return the count of matching items
        function searchInvoiceDetails() {
            const lowerCaseSearchValue = searchinput.toLowerCase();

            const filteredContacts = invoicelist.filter(contact =>
                contact.invoice_number.includes(searchinput) ||
                contact.suppliergstin.includes(searchinput) ||
                contact.suppliername.toLowerCase().includes(lowerCaseSearchValue) ||
                contact.supplieraddrees.toLowerCase().includes(lowerCaseSearchValue) ||
                contact.supplierpin.includes(searchinput) ||
                contact.suppliernumber.includes(searchinput) ||

                contact.customer.name.toLowerCase().includes(lowerCaseSearchValue) ||
                contact.customer.address.toLowerCase().includes(lowerCaseSearchValue) ||
                contact.customer.pin_code.includes(searchinput) ||
                contact.customer.phone_number.includes(searchinput)


            );

            setInvoiceList(filteredContacts)
            setTotalCount(filteredContacts.length)
        }
        return (
            <div className="dasboard invoice">
                <ToastContainer/>
                <div className='pageHdn'>
                    <h1>Invoice List</h1>
                    <span><Link to='/dashboard'>Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i><Link>Invoice List</Link></span>
                </div>
                <div className='comCard'>
                    <div className='comnFltr'>
                        <div className='fltrfrm'>
                            <div className='frmGrp flxFrm'>
                                <input
                                    type='text'
                                    placeholder='Search ...'
                                    value={searchinput}
                                    onChange={(val) => {
                                        if (!val.target.value) {
                                            setInvoiceList(originalData)
                                        }

                                        setSearhInput(val.target.value)
                                    }}
                                    onKeyDown={() => {
                                        if (searchinput === '') {
                                            setInvoiceList(originalData)
                                        }
                                    }}




                                />
                                <button
                                    type='button'
                                    className='goBtn'
                                    onClick={() => searchInvoiceDetails()}
                                >Go</button>
                            </div>

                            <div className='frmGrp'>
                                <input
                                    value={startDate}
                                    onChange={(val) => setStartDate(val.target.value)}
                                    type='date' placeholder='' />
                            </div>
                            <div className='frmGrp'>
                                <input
                                    value={endDate}
                                    onChange={(val) => setendDate(val.target.value)}
                                    type='date' placeholder='' />

                            </div>
                        </div>
                        <div className='fltrfrmbtn'>
                            <button

                                type='button' onClick={() => exporhsnData()} className="exportBtn thtbn">HSN Wise Export</button>
                            <button type='button' onClick={() => exporsaleData()} className="exportBtn">Sale Wise Export</button>
                        </div>
                    </div>
                    <div className='tableresponsive'>
                        <table>
                            <thead>
                                <tr>
                                    <th><div className='checkbox'><input type="checkbox" id="html" /><label for="html"></label></div></th>
                                    <th>Sl No</th>
                                    <th>Invoice Number</th>
                                    <th>Invoice Date</th>
                                    <th>Supplier GSTIN</th>
                                    <th>Supplier Name</th>
                                    <th>Supplier Address</th>
                                    <th>Supplier Pin Code</th>
                                    <th>Supplier Phone Number</th>
                                    <th>Receiver GSTIN</th>
                                    <th>Receiver Name</th>
                                    <th>Receiver Address</th>
                                    <th>Receiver Pincode</th>
                                    <th>Receipver Phone Number</th>
                                    <th>Delivery GSTIN</th>
                                    <th>Delivery Address</th>
                                    <th>Delivery Pincode</th>
                                    <th>Gross MRP Value For The Invoice</th>
                                    <th>Gross Discount  Value For The Invoice</th>
                                    <th>Gross CGST For The Invoice</th>
                                    <th>Gross SGST For The Invoice</th>
                                    <th>Gross IGST For The Invoice</th>
                                    <th>Gross Cess For The Invoice</th>
                                    <th>Gross Sale Value For The Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoicelist?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td><div className='checkbox'><input type="checkbox" id={item.id} /><label for={item.id}></label></div></td>
                                                <td>#{index + 1}</td>
                                                <td>{item.invoice_number != '' ? item.invoice_number : '-- -- --'}</td>
                                                <td>{moment(item.create_date).format('DD-MM-YYYY')}</td>
                                                <td> {item.suppliergstin != undefined && item.suppliergstin != '' ? item.suppliergstin : '-- -- --'
                                                }</td>
                                                <td>{item.suppliername != undefined && item.suppliername != '' ? item.suppliername : '-- -- --'
                                                }</td>
                                                <td>{item.supplieraddrees != undefined && item.supplieraddrees != '' ? item.supplieraddrees : '-- -- --'
                                                }</td>
                                                <td>{item.supplierpin != undefined && item.supplierpin != '' ? item.supplierpin : '-- -- --'
                                                }</td>
                                                <td>{item.suppliernumber != undefined && item.suppliernumber != '' ? item.suppliernumber : '-- -- --'
                                                }</td>
                                                <td>-- -- --</td>
                                                <td>{item.customer.name}</td>
                                                <td>{item.customer.address}</td>
                                                <td>{item.customer.pin_code}</td>
                                                <td>{item.customer.phone_number}</td>
                                                <td>-- -- --</td>
                                                <td>{item.shipping_address.address1}</td>
                                                <td>{item.shipping_address.pincode}</td>
                                                <td>{item.grossmrp != undefined && item.grossmrp != '' ? item.grossmrp : '-- -- --'
                                                }</td>
                                                <td>{item.grossdiscount != undefined && item.grossdiscount != '' ? item.grossdiscount : '-- -- --'
                                                }</td>
                                                <td>{item.grosscgst != undefined && item.grosscgst != '' ? item.grosscgst : '-- -- --'
                                                }</td>
                                                <td>{item.grosssgst != undefined && item.grosssgst != '' ? item.grosssgst : '-- -- --'
                                                }</td>
                                                <td>{item.grossigst != undefined && item.grossigst != '' ? item.grossigst : '-- -- --'
                                                }</td>
                                                <td>{item.grosscess != undefined && item.grosscess != '' ? item.grosscess : '-- -- --'
                                                }</td>
                                                <td>{item.total}</td>
                                            </tr>
                                        )

                                    })
                                }

                            </tbody>
                        </table>
                        <div className='nodata-found-div'>
                            {
                                invoicelist?.length == 0 ?
                                    <p>No Data Found</p>
                                    //  <img 
                                    //  className='table-image'
                                    //  src={require('../../assets/OIP.png')}/>
                                    : null
                            }
                        </div>
                    </div>
                    <div className='tableFtr'>
                        <div className='tblFtrLft'>
                            <label>Show</label>
                            <select
                                value={limit} onChange={(val) => {
                                    setLimit(val.target.value);
                                    setCurrentPage(1)
                                }}
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>

                            </select>
                        </div>
                        <TableDataPagination

                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>

            </div>
        )
    }
    export default Invoice;