// import httpClient from '../baseurl'
import Cookies from 'js-cookie'
import httpClient from '../baseurl'
export async function getUserList(user_id, offset, limit) {
    return httpClient.post(
        `all_users.php`, JSON.stringify({ userid: user_id, offset: offset, limit: limit }), {
        headers: {
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}

export async function updateStatusUser(_data) {
    return httpClient.post(
        `update_user_status.php`, JSON.stringify(_data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}


export async function userexportBydate(_data) {
    return httpClient.post(
        `invoice/dataxls/user_data.php`, JSON.stringify(_data), {
        headers: {
            // "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}
