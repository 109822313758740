function TableDataPagination({ currentPage, totalPages, onPageChange }) {
    const startPage = Math.max(1, currentPage - Math.floor(5 / 2));
    const endPage = Math.min(totalPages, currentPage + Math.floor(5 / 2));
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }
    return (
        <>
            <div className="tblFtrRyt">
                {currentPage > 1 && (
                    <button className="" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                )}
                {startPage > 1 && (
                    <button className="" onClick={() => onPageChange(1)}>
                        1
                    </button>
                )}
                {startPage > 2 && <span className="pagination-ellipsis">...</span>}
                {pages.slice(startPage - 1, endPage).map(page => (
                    <button
                        key={page}
                        className={` ${page === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
                {endPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(totalPages)}>
                        {totalPages}
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pagination-btn" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                )}
            </div>

        </>
    )
}

export default TableDataPagination