import './Header.css';
import headerlogo from "../../assets/headerlogo.png";
import user from "../../assets/user.png"
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Header = () => {
    const navigate = useNavigate()
    const [showlist, setshowlist] = useState(false)
    function logout() {
        Cookies.remove('token')
        navigate('/login')
    }
    return (
        <div className="Header">
            <header>
                <img src={headerlogo} />
                <div className='user'>
                    <img src={user} />
                    <label>super admin</label>
                    <i
                        onClick={() => setshowlist(!showlist)}
                        class="fa fa-chevron-down heade-logout   " aria-hidden="true"></i>
                    {
                        showlist ?
                            <ul className='optionlist'>
                                <li onClick={() => logout()}> <i class="fa fa-sign-out   " aria-hidden="true" /> Logout</li>
                                <li onClick={() => navigate('/changepassword')}><i

                                    class="fa fa fa-cogs " aria-hidden="true" /> Change Password</li>

                            </ul>
                            : null
                    }


                </div>
            </header>
        </div>
    )
}

export default Header