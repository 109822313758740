import Cookies from 'js-cookie'
import httpClient from '../baseurl'

export async function getBannerList(user_id, offset, limit) {

    return httpClient.post(
        '/banner/all_banner.php', JSON.stringify({ userid: user_id, offset: offset, limit: limit }), {
        headers: {
            // "Content-type": "application/json",
            // 'Accept':'application/json',
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )
}

export async function addBannernew(_data) {
    return httpClient.post(
        'banner/add_banner.php', JSON.stringify(_data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,

        }
    }
    )
}

export async function updateBanner(_data) {
    console.log()
    return httpClient.post(
        'banner/update_banner.php', JSON.stringify(_data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,
        }
    }
    )

}

export async function deleteBanner(data) {
    console.log(data, 'data...')
    return httpClient.post(
        'banner/delete_banner.php', JSON.stringify(data), {
        headers: {
            "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,

        }
    }
    )
}
export async function bannerdetailsByid(_data) {
    return httpClient.post(
        'banner/fetch_banner.php', JSON.stringify(_data), {
        headers: {
            // "Content-type": "application/json",
            'Authorization': `${Cookies.get('token')}`,

        }
    }
    )
}