import './Footer.css';
const Footer = ()=>{
    return(
        <div className="Footer">
            <footer>
            <p>copyright © 2024 all rights reserved</p>
            </footer>
        </div>
    )
}

export default Footer