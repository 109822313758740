
import { Link } from 'react-router-dom';
import edit from "../../assets/edit.png";
import trst from "../../assets/delete.png";
import { useNavigate } from 'react-router-dom';
import TableDataPagination from '../../Component/tableComponent/tablePagination';
import { deleteHsnitem, getHsnList } from '../../services/Hsn';
import { useEffect, useState } from 'react';
const HsnCode = () => {
    const [toatlCount, setTotalCount] = useState(0)
    const items = Array.from({ length: toatlCount }, (_, index) => `Item ${index + 1}`);
    const [limit, setLimit] = useState(10)
    let userData = JSON.parse(localStorage.getItem('user'));
    const [selectFilter, setselectFilter] = useState(3)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(items.length / limit);
    const [originalData, setoriginalData] = useState([])
    const navigate = useNavigate()
    const [hsnlist, setHsnList] = useState([])
    const [searchinput, setSearhInput] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    // get businesstype list
    function gethsnListData() {
        getHsnList(userData.userid, currentPage, limit).then((res) => {

            if (res.status == 200) {
                setoriginalData(res?.data?.data?.reverse())
                setTotalCount(res.data.total_count)
            }

        })
    }
    useEffect(() => {
        gethsnListData()
    }, [currentPage, limit])

    // delete business type
    function deleteitem(id) {

        deleteHsnitem({
            userid: userData.userid,
            hsn_id: id
        }).then((res) => {
            if (res.status == 200) {
                gethsnListData()

            }
        })
    }

    // filtred data
    useEffect(() => {
        if (originalData != null) {
            if (selectFilter == 0) {
                let _filterArray = [...originalData].filter(i => i.status == 'false')
                setHsnList(_filterArray)
            } else if (selectFilter == 1) {
                let _filterArray = [...originalData].filter(i => i.status == 'true')
                setHsnList(_filterArray)
            } else {
                console.log(selectFilter, 'filterd')
                setHsnList([...originalData])
            }
        } else {
            return
        }

    }, [selectFilter, originalData])

    // Function to filter contacts and return the count of matching items
    function searchHsnCodebyDetails() {
        const lowerCaseSearchValue = searchinput.toLowerCase();
        console.log(lowerCaseSearchValue, 'lower case value..')
        const filteredContacts = hsnlist.filter(contact =>
            contact.business_type_name.toLowerCase().includes(lowerCaseSearchValue) ||
            contact.code.includes(searchinput)
        );

        setHsnList(filteredContacts)
        setTotalCount(filteredContacts.length)
    }
    return (
        <div className="dasboard hsncode">
            <div className='pageHdn'>
                <h1>HSN Code Master</h1>
                <span><Link to="/">Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i><Link>HSN Code Master</Link></span>
            </div>
            <div className='comCard'>
                <div className='comnFltr'>
                    <div className='fltrfrm'>
                        <div className='frmGrp flxFrm'>
                            <input type='text'
                                placeholder='Search ...'
                                value={searchinput}
                                onChange={(val) => {
                                    if (!val.target.value) {
                                        setHsnList(originalData)
                                    }
                                    setSearhInput(val.target.value)
                                }}
                                onKeyDown={() => {
                                    if (searchinput === '') {
                                        setHsnList(originalData)
                                    }
                                }}

                            />
                            <button type='button'
                                className='goBtn'
                                onClick={() => searchHsnCodebyDetails()}
                            >Go</button>
                        </div>
                        <div className='frmGrp'>
                            <select
                                value={selectFilter}
                                onChange={(val) => setselectFilter(val.target.value)}
                            >
                                <option>Select status</option>
                                <option value={3} >All</option>
                                <option value={1}>Active</option>
                                <option value={0}>Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div className='fltrfrmbtn'>
                        <button type='button' className="exportBtn" onClick={() => { navigate("/hsncodeadd") }}>Add</button>
                    </div>
                </div>
                <div className='tableresponsive'>
                    <table>
                        <thead>
                            <tr>
                                {/* <th><div className='checkbox'><input type="checkbox" id="html" /><label for="html"></label></div></th> */}
                                <th>SI</th>
                                <th>Business Type</th>
                                <th>HSN code</th>
                                <th>Gst rate</th>
                                <th>Cess</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                hsnlist?.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td><div className='checkbox'><input type="checkbox" id={item.id} /><label for={item.id}></label></div></td> */}
                                            <td>
                                                #{index + 1}
                                            </td>
                                            <td>{item?.business_type_name != '' ? item?.business_type_name : '-- -- --'}</td>
                                            <td>{item?.code}</td>
                                            <td>{item?.gst_rate} %</td>
                                            <td>{item?.cess} %</td>
                                            <td>{item.status == 'true' ? <span className='active'>Active</span> : <span>Inactive</span>}</td>
                                            <td>
                                                <div className='action'>
                                                    <Link
                                                        onClick={() => navigate(`/hsncodeadd?id=${item.id}`)}
                                                    > <img src={edit} /></Link>
                                                    <Link
                                                        onClick={() => deleteitem(item.id)}
                                                    ><img src={trst} /></Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className='nodata-found-div'>
                        {
                            hsnlist?.length == 0 ?
                                <p>No Data Found</p>
                                //  <img 
                                //  className='table-image'
                                //  src={require('../../assets/OIP.png')}/>
                                : null
                        }
                    </div>
                </div>
                <div className='tableFtr'>
                    <div className='tblFtrLft'>
                        <label>Show</label>
                        <select
                            value={limit} onChange={(val) => {
                                setLimit(val.target.value);
                                setCurrentPage(1)
                            }}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>

                        </select>
                    </div>
                    <TableDataPagination

                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>

        </div>
    )
}
export default HsnCode;