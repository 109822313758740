import { Link, useLocation, useNavigate } from 'react-router-dom';
import upload from "../../assets/upload.png"
import { useEffect, useState } from 'react';
import { addbusinessType, getdetailsByid, updateBusinessType } from '../../services/businessType';
import { businesstypeAdd } from '../../lib/schema';
import { useFormik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
const AddBusinessType = () => {
  let userData = JSON.parse(localStorage.getItem('user'));
  const history = useLocation()
  const [addBusinessData, setAddBusinessData] = useState({
    userid: userData.userid, bsuiness_type: ''
  })
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search); // Parse query string
  const id = queryParams.get('id');
  const [status, setStatus] = useState(true)

  const { values, errors, touched, handleBlur, handleChange, handleSubmit
  } = useFormik({
    initialValues: {
      userid: userData.userid, business_type: ''
    },
    validationSchema: businesstypeAdd,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async () => {
      if (id != undefined) {
        let _data = { ...values, business_type_id: id, status: status }
        console.log(_data)
        updateBusinessType(_data).then((res) => {
          console.log(res, 'response status..')
          if (res.status === 200) {
            toast.success(res.data.message)
            setTimeout(() => {
              navigate('/managebusiness')

            }, 1000);
          }
        })
      } else {
        addbusinessType(values).then((res) => {
          if (res.status == 200) {
            toast.success(res.data.message)
            navigate('/managebusiness')
          }
        })
      }

    }
  })

  useEffect(() => {
    if (id != undefined) {
      getdetailsByid({ userid: userData.userid, business_type_id: id }).then((res) => {
        values.business_type = res.data.data.business_type
        setStatus(res.data.data.status)
      })
    }

  }, [id])
  return (
    <>
      <ToastContainer />

      <div className="BasicSetting">

        <div className='pageHdn'>
          <h1>Add Business</h1>
          <span><Link to="/layout">Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i>
            <Link to="/managebusiness">Manage Business Type</Link><i class="fa fa-angle-right" aria-hidden="true"></i>
            <Link>Add Business</Link></span>
        </div>
        <form className='settingForm' onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='fdormGroup'>
                <label>Business Type Name</label>
                <input
                  id='business_type'
                  name='business_type'
                  type="text"
                  value={values.business_type}
                  onChange={handleChange}

                />
                <div className='error_div'>
                  {
                    touched.business_type ? (
                      <small className='errorText'>{errors.business_type}</small>
                    )
                      : null
                  }
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='fdormGroup'>
                <label>Status</label>
                <select
                  value={status}
                  onChange={(val) => setStatus(val.target.value)}
                >
                  <option
                    value={true}
                  >
                    Active
                  </option>
                  <option value={false}>
                    Inactive
                  </option>
                </select>
              </div>
            </div>
            <div className='col-md-12'>

              <button type='submit'
                className='themBtns' >{id != undefined ? 'Update' : "Submit"} </button>
            </div>

          </div>
        </form>
      </div>
    </>
  )
}

export default AddBusinessType