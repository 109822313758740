import { Link, NavLink } from "react-router-dom"
import logo from "../../assets/logo.png"
import home from "../../assets/home.png"
import setting from "../../assets/setting.png"
import users from "../../assets/users.png"
import product from "../../assets/product.png"
import city from "../../assets/city.png"
import qr from "../../assets/qr.png"
import list from "../../assets/list.png";
import { useState } from "react"
import './Menu.css';
const Menu = () => {
  const [isActive, setisActive] = useState(0)
  const menu = [{
    label: "Dashboard",
    url: "/dashboard",
    icon: home
  },
  {
    label: "Basic Settings",
    url: "/settings",
    icon: setting
  },
  {
    label: "Banner",
    url: "/banner",
    // icon: users
  },
  {
    label: "Manage User",
    url: "/manageuser",
    icon: users
  },
  {
    label: "Manage Customer",
    url: "/managecustomer",
    icon: product
  },
  {
    label: "Manage Business Type",
    url: "/managebusiness",
    icon: city
  },
  {
    label: "HSN Code Master",
    url: "/hsncode",
    icon: qr
  },
  {
    label: "Invoice List",
    url: "/invoice",
    icon: list
  }];
  return (
    <div className="menu">
      <nav>
        <div className="logo">
          <Link to='/dashboard'>
            <img src={logo} /></Link>
        </div>
        <ul>
          {
            menu.map((item, index) => {
              return <li key={index}>
                <NavLink

                  to={item.url} onClick={() => { setisActive(index) }}>
                  {
                    item.label == 'Banner' ? <span><i class="banner_menu fa fa-picture-o" aria-hidden="true"></i></span> : <img src={item.icon} />
                  }

                  <span className={item.label == 'Banner' ? 'banner_lable' : ''}>{item.label}</span></NavLink></li>
            })
          }
          <li></li>
        </ul>
      </nav>
    </div>
  )
}

export default Menu