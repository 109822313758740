import './ManageCustomer.css';
import { Link } from 'react-router-dom';
import usert from "../../assets/usert.png";
import edit from "../../assets/edit.png";
import trst from "../../assets/delete.png";
import TableDataPagination from '../../Component/tableComponent/tablePagination';
import { useEffect, useState } from 'react';
import { getCustommerList } from '../../services/customer';
const ManageCustomer = () => {
    const [toatlCount, setTotalCount] = useState(0)
    const [limit, setLimit] = useState(10)
    let userData = JSON.parse(localStorage.getItem('user'));
    const items = Array.from({ length: toatlCount }, (_, index) => `Item ${index + 1}`);
    const totalPages = Math.ceil(items.length / limit);
    const [customerList, setCustomerList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectFilter, setselectFilter] = useState(3);
    const [originalData, setOriginalData] = useState([])
    const [searchinput, setSearhInput] = useState('')

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    useEffect(() => {
        getCustommerList(userData.userid, currentPage, limit).then((res) => {
            if (res.error != true) {
                
                setOriginalData(res.data.data)
                setTotalCount(res.data.total_count)

            }else if(res.error == true){
                console.log('no data ....')
                setOriginalData([])
                setTotalCount(0)
            }
        })
    }, [currentPage, limit])

    // filtred data
    useEffect(() => {
        if(originalData != null){
            if (selectFilter == 0) {
                let _filterArray = [...originalData]?.filter(i => i.status == 'false')
                setCustomerList(_filterArray)
            } else if (selectFilter == 1) {
                let _filterArray = [...originalData]?.filter(i => i.status == 'true')
                setCustomerList(_filterArray)
            } else {
                // console.log('data...fmdlfsk')
                setCustomerList([...originalData])
            }
        }
      
    }, [selectFilter, originalData])

    // Function to filter contacts and return the count of matching items
    function searcchhByCustomerDetails() {
        const lowerCaseSearchValue = searchinput.toLowerCase();

        const filteredContacts = customerList.filter(contact =>
            contact.name.toLowerCase().includes(lowerCaseSearchValue) ||
            contact.phone_number.includes(lowerCaseSearchValue)
        );

        setCustomerList(filteredContacts)
        setTotalCount(filteredContacts.length)
    }
    return (
        <div className="dasboard">
            <div className='pageHdn'>
                <h1>Manage Customer</h1>
                <span><Link to="/dashboard">Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i><Link>Manage Customer</Link></span>
            </div>
            <div className='comCard'>
                <div className='comnFltr'>
                    <div className='fltrfrm'>
                        <div className='frmGrp flxFrm'>
                            <input type='text'
                                placeholder='Search ...'
                                value={searchinput}
                                onChange={(val) => {
                                    if (!val.target.value) {
                                        setCustomerList(originalData)
                                    }
                                    setSearhInput(val.target.value)}}
                                onKeyDown={() => {
                                    if (searchinput === '') {
                                        setCustomerList(originalData)
                                    }
                                }}

                            />
                            <button
                                type='button'
                                className='goBtn'
                                onClick={() => searcchhByCustomerDetails()}
                            >Go</button>
                        </div>
                        <div className='frmGrp'>
                            <select value={selectFilter}
                                onChange={(val) => setselectFilter(val.target.value)}
                            >
                                <option>Select status</option>
                                <option value={3}>All</option>
                                <option value={1}>Active</option>
                                <option value={0}>Inactive</option>
                            </select>

                        </div>
                    </div>


                    <div className='fltrfrmbtn'>
                    </div>
                </div>
                <div className='tableresponsive'>
                    <table>
                        <thead>
                            <tr>
                                {/* <th><div className='checkbox'><input type="checkbox" id="html" /><label for="html"></label></div></th> */}
                                <th>SI</th>
                                <th>

                                    Customer Name
                                </th>
                                <th>Contact No</th>
                                <th>GST No</th>
                                <th>Address</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>

                            {

                                customerList.map((item, index) => {
                                    return (
                                        <tr>
                                            {/* <td><div className='checkbox'><input type="checkbox" id={index} /><label for={index}></label></div></td> */}
                                            <td>#{index + 1}</td>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>{item.phone_number}</td>
                                            <td>{item.gst_no != '' ? item.gst_no : '-- -- --'}</td>
                                            <td >{item.address}</td>
                                            <td>{item.status === 'true' ? <span className='active'>Active</span> : <span className='inactive'>Inactive</span>}</td>
                                        </tr>
                                    )
                                })
                            }
                            {/* {item} */}
                        </tbody>
                    </table>
                </div>
                <div className='tableFtr'>
                    <div className='tblFtrLft'>
                        <label>Show</label>
                        <select value={limit} onChange={(val) => {
                            setLimit(val.target.value);
                            setCurrentPage(1)
                        }}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>

                        </select>
                    </div>
                    <TableDataPagination

                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>

        </div>
    )
}
export default ManageCustomer;