import { Outlet } from 'react-router-dom';
import './Layout.css';
import Header from '../../Component/Header/Header';
import Menu from '../../Component/Menu/Menu';
import Footer from '../../Component/Footer/Footer';
const Layout = () => {
  return (
    <div className="layout">
      <Header />
      <div className='pageWapper'>
        <Menu />
        <div className='wapper'>
          <div className='mainwapper'>
            <Outlet />
          </div>

          <Footer />
        </div>
      </div>


    </div>
  )
}
export default Layout;