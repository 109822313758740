import './ManageUser.css';
import { Link, useNavigate } from 'react-router-dom';
import usert from "../../assets/usert.png";
import edit from "../../assets/edit.png";
import trst from "../../assets/delete.png";
import TableDataPagination from '../../Component/tableComponent/tablePagination';
import { useEffect, useState } from 'react';
import { getUserList, updateStatusUser, userexportBydate } from '../../services/users/user';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
const ManageUser = () => {
    const [toatlCount, setTotalCount] = useState(0)
    const [limit, setLimit] = useState(10)
    let userData = JSON.parse(localStorage.getItem('user'));
    const items = Array.from({ length: toatlCount }, (_, index) => `Item ${index + 1}`);
    const totalPages = Math.ceil(items.length / limit);
    const [userList, setUserList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectFilter, setselectFilter] = useState(3);
    const [originalData, setOriginalData] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [searchinput, setSearhInput] = useState('')
    const navigate = useNavigate()
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    function getuserlistData() {
        getUserList(userData.userid, currentPage, limit).then((res) => {
            if (res.status == 200) {
                setOriginalData(res.data.data)
                setTotalCount(res.data.total_count)

            } else (
                toast.error('Something Wrong')
            )
        })

    }

    useEffect(() => {
        getuserlistData()
    }, [currentPage, limit])

    // filtred data
    useEffect(() => {
        if (originalData != null) {
            if (selectFilter == 0) {
                let _filterArray = [...originalData].filter(i => i.status == 'false')
                // setTotalCount(_filterArray.length)
                setUserList(_filterArray)
            } else if (selectFilter == 1) {
                let _filterArray = [...originalData].filter(i => i.status == 'true')
                // setTotalCount(_filterArray.length)
                setUserList(_filterArray)
            } else {
                setUserList([...originalData])

            }
        } else {
            return
        }

    }, [selectFilter, originalData])

    // on status change
    function onChangestatus(data) {
        let _data = {
            userid: userData.userid,
            user_id: data.id,
            status: data.status
        }
        console.log(_data)
        updateStatusUser(_data).then((res) => {
            toast.success(res.data.message)
            getuserlistData()
        })

    }

    // export user data
    function exportUserDataByDate() {
        if (startDate == '') {
            toast.error('Please Select Start Date')
            return
        } else if (endDate == '') {
            toast.error('Please Select End Date')
            return
        }
        userexportBydate({ userid: userData.userid, start_date: startDate, end_date: endDate }).then((res) => {

            if (res.data.error == true) {
                toast.error(res.data.message)
                return
            }

            if (res.data && res.data.xzlsurl) {
                const handleDownload = () => {
                    const link = document.createElement('a');
                    link.href = res.data.xzlsurl; // Replace with your file path
                    link.download = 'Users_excel_sheet.xlsx'; // The name the file will be downloaded as
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };
                handleDownload()
            }

        })
    }



    // Function to filter contacts and return the count of matching items
    function searcchhByuserDetails() {
        const lowerCaseSearchValue = searchinput.toLowerCase();

        const filteredContacts = userList.filter(contact =>
            contact.name.toLowerCase().includes(lowerCaseSearchValue) ||
            contact.email.toLowerCase().includes(lowerCaseSearchValue) ||
            contact.mobile_no.includes(lowerCaseSearchValue)
        );

        setUserList(filteredContacts)
        setTotalCount(filteredContacts.length)
    }

    return (
        <div>

            <ToastContainer />
            <div className="dasboard">
                <div className='pageHdn'>
                    <h1>Manage User</h1>
                    <span><Link to='/dashboard'>Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i><Link>Manage User</Link></span>
                </div>
                <div className='comCard'>
                    <div className='comnFltr'>
                        <div className='fltrfrm'>
                            <div className='frmGrp flxFrm'>
                                <input type='text'
                                    value={searchinput}
                                    onChange={(val) => {
                                        if (!val.target.value) {
                                            setUserList(originalData)
                                        }
                                        setSearhInput(val.target.value)
                                        // if (val.target.value == null) {
                                        //     setUserList(originalData)
                                        // }

                                    }}
                                    placeholder='Search ...'
                                    onKeyDown={() => {
                                        if (searchinput === '') {
                                            setUserList(originalData)
                                        }
                                    }}

                                />
                                <button type='button'


                                    onClick={() => searcchhByuserDetails()}
                                    className='goBtn'>Go</button>
                            </div>
                            <div className='frmGrp'>
                                <select value={selectFilter}
                                    onChange={(val) => setselectFilter(val.target.value)}
                                >
                                    <option>Select status</option>
                                    <option value={3}>All</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>

                            </div>
                            <div className='frmGrp'>
                                <input type='date'
                                    value={startDate}
                                    onChange={(val) => setStartDate(val.target.value)}
                                    placeholder=''
                                />
                            </div>
                            <div className='frmGrp'>
                                <input type='date'
                                    value={endDate}
                                    onChange={(val) => setendDate(val.target.value)}
                                    placeholder=''
                                />
                            </div>
                        </div>
                        <div className='fltrfrmbtn'>
                            <button type='button' className="exportBtn"
                                onClick={() => exportUserDataByDate()}
                            >Export</button>
                        </div>
                    </div>
                    <div className='tableresponsive'>
                        <table>
                            <thead>
                                <tr>
                                    <th><div className='checkbox'><input type="checkbox" id="html" /><label for="html"></label></div></th>
                                    <th>

                                        User Name

                                    </th>
                                    <th>email</th>
                                    <th>mobile no</th>
                                    {/* <th>client status</th> */}
                                    <th>Package Expired</th>
                                    <th>Status</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userList?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td><div className='checkbox'><input type="checkbox" id={index} /><label for={index}></label></div></td>
                                                <td>
                                                    <div className='tduser'>
                                                        <img src={item.customer_img_url != '' ? item.customer_img_url : usert} />
                                                        <p>{item.name != '' ? item.name : '-- -- --'}</p>
                                                    </div>
                                                </td>
                                                <td>{item.email != '' ? item.email : '-- -- --'}</td>
                                                <td>{item.mobile_no != '' ? item.mobile_no : '-- -- -- '}</td>
                                                {/* <td className='prmn'>Premium</td> */}
                                                <td>{item.expire_date != '' ? moment(item.expire_date).format('DD-MM-YYYY') : '-- -- --'}</td>
                                                <td>

                                                    <div
                                                        className={`switch ${item?.status === 'true' ? 'on' : 'off'}`}
                                                        onClick={() => onChangestatus(
                                                            {
                                                                id: item?.userid,
                                                                status: item?.status === 'true' ? 'false' : 'true'
                                                            }
                                                        )}
                                                        role="switch"
                                                        aria-checked={item?.status}
                                                        aria-label="Toggle switch"
                                                    >
                                                        <div className="switch-handle" />
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <div className='action'>
                                                        <Link 
                                                        onClick={() =>  navigate(`/updateuser?id=${item.id}`)}
                                                        > <img src={edit} /></Link>
                                                        <Link><img src={trst} /></Link>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                        <div className='nodata-found-div'>
                            {
                                userList?.length == 0 ?
                                    <p>No Data Found</p>
                                    //  <img 
                                    //  className='table-image'
                                    //  src={require('../../assets/OIP.png')}/>
                                    : null
                            }
                        </div>
                    </div>
                    <div className='tableFtr'>
                        <div className='tblFtrLft'>
                            <label>Show</label>
                            <select value={limit} onChange={(val) => {
                                setLimit(val.target.value);
                                setCurrentPage(1)
                            }}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>

                            </select>
                        </div>
                        <TableDataPagination

                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ManageUser;