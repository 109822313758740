import { useFormik } from "formik"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { addbannerschema } from "../../lib/schema"
import { useEffect, useState } from "react"
import upload from '../../assets/upload.png'
import Cookies from 'js-cookie'
import httpClient from "../../services/baseurl"
import cross from '../../assets/Frame.png'
import { addBannernew, bannerdetailsByid, updateBanner } from "../../services/banner"
import { toast, ToastContainer } from "react-toastify"

function AddeditBanner() {
    let userData = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');
    const [initial_value, setinitivalue] = useState({
        userid: userData.userid,
        name: '',
        banner_image: ''
    })
    const [uploadLogo, setUoloadlogo] = useState('')

    useEffect(() => {
        if (id != undefined) {
            bannerdetailsByid({ userid: userData.userid, bannerid: id }).then((res) => {
                setinitivalue({ ...initial_value, name: res.data.data.name, banner_image: res.data.data.banner_image })
                setUoloadlogo(res.data.data.banner_image)
            })
        }

    }, [id])
    const { values, errors, touched, handleBlur, handleChange, handleSubmit
    } = useFormik({
        initialValues: initial_value,
        validationSchema: addbannerschema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async () => {
            if (id != undefined) {
                let _data = { ...values, bannerid: id }
                updateBanner(_data).then((res) => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            toast.success(res.data.message)

                        }, 1000);
                        navigate('/banner')
                    }
                })
            } else {
                addBannernew(values).then((res) => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            toast.success(res.data.message)

                        }, 1000);
                        navigate('/banner')
                    }
                })

            }

        }
    })
    // logoupload 
    function Logoupload(e) {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        httpClient.post(
            `banner/upload_banner.php`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${Cookies.get('token')}`,
            }
        }
        ).then((res) => {
            setUoloadlogo(res.data.url)
            values.banner_image = res.data.url
            console.log(res.data, 'response data...')
        })
    }
    return (
        <>

            <div className="BasicSetting">
                <div>
                    <ToastContainer />

                </div>
                <div className='pageHdn'>
                    <h1>Add Banner</h1>
                    <span><Link to="/dashboard">Dashboards</Link><i class="fa fa-angle-right" aria-hidden="true"></i>
                        <Link to="/banner">Banner</Link><i class="fa fa-angle-right" aria-hidden="true"></i>
                        <Link>Add Banner</Link></span>
                </div>
                <form className='settingForm' onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='fdormGroup'>
                                <label>Banner Name</label>
                                <input
                                    name='name'
                                    id='name'
                                    autoCapitalize='none'
                                    autoCorrect='off'
                                    type='text'
                                    placeholder='Banner Name'
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <div className='error_div'>
                                    {
                                        touched.name && errors.name ? (
                                            <small className='errorText'>{errors.name}</small>
                                        )
                                            : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='fdormGroup'>
                            <label>Upload banner</label>
                            <div className='upload'>
                                <span onChange={(e) => Logoupload(e)}  >
                                    <img src={upload} /> Drop files here or click to upload.
                                    <input type='file' />
                                </span>
                            </div>
                            <div className='error_div'>
                                {
                                    touched.banner_image && errors.name ? (
                                        <small className='errorText'>{errors.banner_image}</small>
                                    )
                                        : null
                                }
                            </div>
                        </div>
                        {
                            uploadLogo != '' ?
                                <div className='image_container'>
                                    <div className='upload_image_view'>
                                        <div className='upload_image'>
                                            <img
                                                className='image_logo'
                                                src={uploadLogo}
                                            />


                                        </div>
                                        <img
                                            onClick={() => setUoloadlogo('')}
                                            className='image'
                                            src={cross}
                                        />

                                    </div>
                                </div>
                                : null
                        }

                        <button type='submit' className='themBtns'>{id != undefined ? 'Update' : 'Submit'}</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddeditBanner